import { render, staticRenderFns } from "./FooterSection.vue?vue&type=template&id=88b07138&scoped=true&"
import script from "./FooterSection.vue?vue&type=script&lang=js&"
export * from "./FooterSection.vue?vue&type=script&lang=js&"
import style0 from "./FooterSection.vue?vue&type=style&index=0&id=88b07138&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "88b07138",
  null
  
)

export default component.exports